import { createStore } from 'react-hooks-global-state'
import { dialogType } from '../../types/app'

export interface IPalletData {
  lat: string
  lng: string
  title: string
  status: 'planned' | 'installed'
}

export interface IState {
  palletDataLoading: boolean | null
  palletData: IPalletData[] | null
  currentPallet: IPalletData | null
  dialogState: dialogType | null
  dialogFull: boolean
  dialogBroken: boolean
  dialogGone: boolean
  dialogOther: boolean
  headerInverted: boolean
}

type Action =
  | { type: 'setPalletDataLoading'; value: boolean }
  | { type: 'setPalletData'; value: IPalletData[] }
  | { type: 'setCurrentPallet'; value: IPalletData | null }
  | { type: 'setDialogState'; value: IState['dialogState'] }
  | { type: 'setHeaderInverted'; value: boolean }

const initialState: IState = {
  palletDataLoading: null,
  palletData: null,
  currentPallet: null,
  dialogState: null,
  dialogFull: false,
  dialogBroken: false,
  dialogGone: false,
  dialogOther: false,
  headerInverted: false
}

const stateReducer = (state: IState, action: Action) => {
  switch (action.type) {
    case 'setPalletDataLoading':
      return {
        ...state,
        palletDataLoading: action.value
      }

    case 'setPalletData':
      return {
        ...state,
        palletData: action.value
      }

    case 'setCurrentPallet':
      return {
        ...state,
        currentPallet: action.value
      }

    case 'setDialogState':
      return {
        ...state,
        dialogState: action.value
      }

    case 'setHeaderInverted':
      return {
        ...state,
        headerInverted: action.value
      }

    default:
      return state
  }
}

export const { dispatch, useGlobalState } = createStore(stateReducer, initialState)
