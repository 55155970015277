import { GatsbyLinkProps, Link as GatsbyLink } from 'gatsby'
import React, { useContext } from 'react'
import { I18nextContext } from './i18nextContext'
import { LANGUAGE_KEY } from './types'

export interface LinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  language?: string
}

export const Link: React.FC<LinkProps> = ({ language, to, onClick, ...rest }) => {
  const { language: lng, defaultLanguage } = useContext(I18nextContext)
  const urlLanguage = language || lng
  const link = urlLanguage !== defaultLanguage ? `/${urlLanguage}${to}` : to

  return (
    <GatsbyLink
      {...rest}
      to={link}
      hrefLang={urlLanguage}
      onClick={(e) => {
        if (language) {
          localStorage.setItem(LANGUAGE_KEY, language)
        }
        if (onClick) {
          onClick(e)
        }
      }}
    />
  )
}
