import { NavigateOptions } from '@reach/router'
import { navigate as gatsbyNavigate } from 'gatsby'
import { i18n as Ii18n, TFunction } from 'i18next'
import { useContext } from 'react'
import { Namespace, useTranslation, UseTranslationOptions } from 'react-i18next'
import { I18nextContext } from './i18nextContext'
import { I18NextContext, LANGUAGE_KEY } from './types'

declare let __BASE_PATH__: string | undefined
declare let __PATH_PREFIX__: string | undefined

export interface useI18NextReturn {
  language: string
  routed: boolean
  languages: string[]
  defaultLanguage: string
  originalPath: string
  path: string
  siteUrl?: string
  i18n: Ii18n
  t: TFunction
  ready: boolean
  navigate: (
    to: string,
    navigateOptions?: NavigateOptions<Record<string, unknown>> | undefined
  ) => Promise<void>
  changeLanguage: (
    language: string,
    to?: string | undefined,
    changeOptions?: NavigateOptions<Record<string, unknown>> | undefined
  ) => Promise<void>
}

export const useI18next = (ns?: Namespace, options?: UseTranslationOptions): useI18NextReturn => {
  const { i18n, t, ready } = useTranslation(ns, options)
  const context: I18NextContext = useContext(I18nextContext)

  const { routed, defaultLanguage } = context

  const getLanguagePath = (language: string) => {
    return language !== defaultLanguage ? `/${language}` : ''
  }

  const removePrefix = (pathname: string) => {
    let p = pathname
    const base = typeof __BASE_PATH__ !== `undefined` ? __BASE_PATH__ : __PATH_PREFIX__
    if (base && pathname.indexOf(base) === 0) {
      p = pathname.slice(base.length)
    }
    return p
  }

  const removeLocalePart = (pathname: string) => {
    if (!routed) return pathname
    const i = pathname.indexOf(`/`, 1)
    return pathname.substring(i)
  }

  const navigate = (to: string, navigateOptions?: NavigateOptions<Record<string, unknown>>) => {
    const languagePath = getLanguagePath(context.language)
    const link = routed ? `${languagePath}${to}` : `${to}`
    return gatsbyNavigate(link, navigateOptions)
  }

  const changeLanguage = (
    language: string,
    to?: string,
    changeOptions?: NavigateOptions<Record<string, unknown>>
  ) => {
    const languagePath = getLanguagePath(language)
    const pathname = to || removeLocalePart(removePrefix(window.location.pathname))
    const link = `${languagePath}${pathname}${window.location.search}`
    localStorage.setItem(LANGUAGE_KEY, language)
    return gatsbyNavigate(link, changeOptions)
  }

  return {
    ...context,
    i18n,
    t,
    ready,
    navigate,
    changeLanguage
  }
}
