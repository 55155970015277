import classNames from 'classnames'
import React from 'react'

type ButtonProps = JSX.IntrinsicElements['button']

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(
        'relative flex items-center justify-center px-4 py-3 space-x-2 text-white transition-all duration-300 bg-teal-500 rounded fill-current disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    />
  )
)

export const ButtonGhost = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(
        'relative flex items-center justify-center px-4 py-3 space-x-2 text-teal-700 transition-all duration-300 bg-transparent rounded fill-current hover:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    />
  )
)

export const ButtonOutline = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(
        'relative flex justify-center px-4 py-3 space-x-2 text-teal-700 transition-all duration-300 bg-white border border-teal-300 rounded fill-current hover:border-teal-700 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    />
  )
)

export const ButtonOutlineSmall = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => (
    <button
      ref={ref}
      className={classNames(
        'relative flex items-center justify-center px-2 py-1 space-x-2 text-sm leading-none text-teal-700 transition-all duration-300 bg-white border border-teal-300 rounded fill-current hover:border-teal-700 disabled:cursor-not-allowed disabled:opacity-50',
        className
      )}
      {...props}
    />
  )
)
